import React from "react"
import Helmet from "react-helmet"
import SiteLinks from "../components/SiteLinks"

export const frontmatter = {
  layoutType: `page`,
  path: `/wip/`,
}

class About extends React.Component {
  render() {
    return (
      <div className="box container">
        <p>Work in progress....</p>
      </div>
    )
  }
}

export default About
